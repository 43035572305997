<div class="page">
    <div class="page-title">
        <div class="container">
            <h1>Seja nosso parceiro</h1>
        </div>    
    </div>
    <div class="page-content">
        <div class="container">
            lorem ipsun
        </div>    
    </div>
</div>        
