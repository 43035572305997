import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationStart, NavigationEnd,Event as NavigationEvent, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ContatoConsultorFormComponent } from '../contato/contato-consultor-form/contato-consultor-form.component';

declare var $:any;

@Component({
  selector: 'modulo-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @Input("classCustom") classCustom:any;
  @Input("mobile") mobile = false;
  public subMenu  = false;
  public showMenu = null;

  constructor(
    public router: Router,
    public dialog: MatDialog,
    private deviceService: DeviceDetectorService
  ){ }

  /**
   * 
   * To section
   * 
   */
   toSection(id:any){

    if(id == "#home"){

      $('html, body').stop().animate({ 
        scrollTop: 0
      }, 500);

    }else{
      
      var top = $(id).offset().top; 
          
      $('html, body').stop().animate({ 
        scrollTop: top-50
      }, 500);

    }
    this.showMenu = false;

  }
  /**
   * 
   * 
   */
  _subMenu(){

    this.subMenu = this.subMenu == true ? false : true;

  }
  /**
   * 
   * On Events
   * 
   */
  onEvents(){

    this.router.events.forEach((event:NavigationEvent) =>{
      
      if(event instanceof NavigationEnd){
        
        this.showMenu = false; 
          
      }
              
    });

  }
  /**
   * 
   * Button open
   * 
   */
  _open(){

    this.showMenu = this.showMenu == true ? false : true; 

  }
  /**
   * 
   * Open modal contato
   * 
   */
  openModalContato(){

    this.dialog.open(
      ContatoConsultorFormComponent,{
        width: "650px"
      }  
    );

  }
  /**
   * 
   * Iniciliza 
   * 
   */
  ngOnInit(){
    this.onEvents();  
  }

}
