<div class="module about-managetour-module" (window:resize)="_onResize()">
    <div class="box box-about">
        <div class="container">
            <div class="title">
                <h2 class="wow slideInLeft" data-wow-delay="0.2s">{{data.titulo}}</h2>
                <h3 class="wow slideInDown" data-wow-delay="0.5s">{{data.subtitulo}}</h3>
            </div>
            <div class="linha"></div>
            <div class="description wow fadeIn" data-wow-delay="0.5s" [innerHtml]="data.params.texto"></div>  
            <div class="img-sistema wow slideInUp" data-wow-delay="0.5s">
                <img src="/images/managetour/{{data.params.imagem}}" alt="Sistema ManageTour" width="700px" />
            </div>    
        </div>
    </div> 
    <div class="box box-list-features">
        <div class="container">
            <div class="list-features">  
                <div class="content-item divisor" *ngIf="width < 700">
                    <h3>Principais funções</h3>
                    <span></span>
                </div>
                <div class="content-item">
                    <div class="item wow slideInLeft" data-wow-delay="0.2s" *ngFor="let d of itensLeft">
                        <div class="icon">
                            <i class="material-icons">{{d.icone}}</i>   
                        </div>    
                        <h3>{{d.titulo}}</h3>
                        <article [innerHtml]="d.descricao"></article>
                    </div>
                </div>  
                <div class="content-item divisor" *ngIf="width > 700">
                    <h3>Principais funções</h3>
                    <span></span>
                </div>
                <div class="content-item">
                    <div class="item wow slideInRight" data-wow-delay="0.3s" *ngFor="let d of itensRight">
                        <div class="icon">
                            <i class="material-icons">{{d.icone}}</i> 
                        </div>    
                        <h3>{{d.titulo}}</h3>
                        <article [innerHtml]="d.descricao"></article>
                    </div>
                </div>
            </div>   
        </div> 
    </div>   
</div>    