<div class="module newsletter-module">
  <div class="module-title">
      <h3>{{data.titulo}}</h3>
      <h4>{{data.subtitulo}}</h4>
  </div>
  <div class="module-content">
      <form class="form-custom" (submit)="send()">
          <div class="row">
              <div class="col-md-6">
                  <div class="form-group">
                      <label>Seu nome:</label>
                      <input type="text" [(ngModel)]="dataItem.nome" name="nome" autocomplete="off" autocapitalize="off" />
                  </div>
              </div>
              <div class="col-md-6">
                  <div class="form-group">
                      <label>Seu e-mail:</label>
                      <input type="text" [(ngModel)]="dataItem.email" name="email" placeholder="nome@provedor.com.br" />
                  </div>
              </div>
          </div>
          <div class="row">
              <div class="col-md-6">
                  <div class="form-group">
                      <label>Seu WhatsApp:</label>
                      <input type="text" [(ngModel)]="dataItem.telefone" name="telefone" placeholder="(**) *****-****" mask="(99) 99999-9999" />
                  </div> 
              </div>
              <div class="col-md-6">
                  <div class="form-group">
                      <button type="submit" class="btn-two btn-icon">
                          <i class="material-icons">send</i>
                          <span>Enviar</span>
                      </button>    
                  </div>
              </div>    
          </div>    
      </form>
  </div>    
</div>    
<loader [hidden]="!loader"></loader>   
  