<div class="mat-modal-info"> 
    <div class="mat-modal-info-content">
      <article class="message" [innerHtml]="data.message"></article>    
    </div>
    <div class="mat-modal-info-footer">
      <button type="modal" class="btn-one" (click)="close()">
        <i class="material-icons">check_circle</i>
        <span>OK</span>
      </button> 
    </div>   
</div>
    