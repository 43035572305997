<div class="page page-about">
    <div class="page-body" *ngIf="response.status == 1">
        <div class="page-title" *ngIf="page.mostrar_titulo">
            <div class="container">
                <h1 class="wow slideInUp">{{page.titulo}}</h1>
                <h2 class="wow slideInDown" *ngIf="page.mostrar_subtitulo">{{page.subtitulo}}</h2>
            </div>    
        </div>
        <div class="page-content">
            <section class="section section-description">
                <div class="container"> 
                    <article class="wow slideInUp" [innerHtml]="page.texto | safeHtml"></article> 
                </div>    
            </section>
            <section class="section section-vision" *ngIf="params.texto_visao_ativo">
                <div class="container"> 
                    <div class="title"> 
                        <figure class="logo animated-five pulse infinite">
                            <img src="/images/icons/logo-simbolo.svg" width="80px" />  
                        </figure>
                        <h3 class="wow slideInUp">
                            Visão Plugtour
                        </h3>
                    </div>
                    <article class="description" [innerHtml]="params.texto_visao | safeHtml"></article> 
                </div>    
            </section>
            <section class="section section-mission" *ngIf="params.texto_missao_ativo">
                <div class="container"> 
                    <div class="title">
                        <figure class="logo animated-five pulse infinite">
                            <img src="/images/icons/logo-simbolo.svg" width="80px" />  
                        </figure>
                        <h3>
                            <span>Missão Plugtour</span>
                        </h3> 
                    </div>
                    <article class="description" [innerHtml]="params.texto_missao | safeHtml"></article> 
                </div>    
            </section>
            <section class="section section-values" *ngIf="valores.length > 0">
                <div class="container">
                    <div class="title">
                        <figure class="logo animated-five pulse infinite">
                            <img src="/images/icons/logo-simbolo.svg" width="80px" />  
                        </figure>
                        <h3 class="wow slideInUp">Valores Plugtour</h3>
                    </div>
                    <div class="content">
                        <div class="row">
                            <div class="col-md-4" *ngFor="let v of valores">
                                <div class="item-value wow slideInDown">
                                    <div class="icon">
                                        <img [src]="v.icone" [alt]="v.titulo" width="70px" />
                                    </div>
                                    <h4>{{v.titulo}}</h4>
                                    <article [innerHtml]="v.texto | safeHtml"></article>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
    <div class="page-body" *ngIf="response.status == 500">
        <page-error (reload)="getData()"></page-error>   
    </div>    
</div>        
<loader [hidden]="!loader" message="Carregando. Aguarde!"></loader> 