<div class="modal">
    <div class="modal-header">
        <h3>Fale com um especialista</h3>
        <div class="icon" (click)="dialog.close()">
            <i class="material-icons">close</i>
        </div>    
    </div>
    <div class="modal-content"> 
        <contato-consultor-two (success)="_success($event)"></contato-consultor-two>
    </div>    
</div>