import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'managetour-modulo',
  templateUrl: './managetour-modulo.component.html',
  styleUrls: ['./managetour-modulo.component.scss']
})
export class ManagetourModuloComponent implements OnInit {

  @Input("data") data:any         = {};
  @Input("template") template:any = {}
  public itensLeft:any            = [];
  public itensRight:any           = [];
  public width = null;

  constructor(){}

  /**
   * 
   * Init
   * 
   */
  setData(){

    for (let index = 0; index < this.data.funcoes.length; index++) {
      
      if(index % 2){
        this.itensRight.push(this.data.funcoes[index]);
      }else{
        this.itensLeft.push(this.data.funcoes[index]);
      }
      
    }

  }
  /**
  * 
  * On 
  * 
  **/
  _onResize(){
    this.width = window.innerWidth; 
  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
    this.setData();
    this.width = window.innerWidth;
  }

}
