<div class="banner-slider-module" (window:resize)="_onResize()">
    <div #banner class="banner-slider-slick-content">
      <div *ngFor="let i of data.banners;let y = index" class="item">
          <div class="content">
              <div class="description">
                  <h4  class="wow slideInDown" data-wow-delay="0.3s" *ngIf="i.nome_ativo">{{i.nome}}</h4>
                  <h2  class="wow fadeIn" data-wow-delay="0.8s" *ngIf="i.titulo_ativo">{{i.titulo}}</h2> 
                  <h3  class="wow slideInUp" data-wow-delay="0.5s" *ngIf="i.mostrar_legenda">{{i.legenda}}</h3>
              </div>    
              <img [src]="i.imagem" [alt]="i.alt" *ngIf="width > 700" width="100%" />
              <img [src]="i.imagem" [alt]="i.alt" *ngIf="width < 700 && !i.imagem_mobile_ativo" width="100%" />
              <img [src]="i.imagem_mobile" [alt]="i.alt" *ngIf="width <= 700 && i.imagem_mobile_ativo" width="100%" />
          </div>    
      </div>
    </div>
    <div class="slide-navigate-item prev" (click)="prev()">
      <img src="/images/icons/prev-01.svg" alt="Prev" width="40px" />
    </div>
    <div class="slide-navigate-item next" (click)="next()">    
        <img src="/images/icons/next-01.svg" alt="Next" width="40px" /> 
    </div> 
</div>
  
  
  