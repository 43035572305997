<div class="menu {{classCustom}}">
  <div class="button-open">
    <button type="button" class="menu-open" (click)="_open()">
        <i class="material-icons">menu</i>   
    </button>    
  </div>  
  <div class="menu-content" [ngClass]="{'show-menu scale-up-ver-top': showMenu}"> 
      <ul>
          <li>
              <a routerLink="/" title="Plugtour - Especialista em Tecnologia">
                Home
              </a>    
          </li>
          <li>
            <a routerLink="/quem-somos" title="Plugtour - Sobre a Plugtour">
                Sobre Nós
            </a>    
          </li>
          <li>
            <a routerLink="/planos" title="Plugtour - Planos">
              Planos
            </a>    
          </li>
          <li>
              <a routerLink="/contato" title="Contato - Fale com os nossos especialistas">
                Contato
              </a>      
          </li>
          <li class="li-button"> 
            <div class="content-button"> 
              <btn-consultor-demonstracao></btn-consultor-demonstracao>
            </div>
          </li>  
      </ul>  
  </div> 
</div>    
