import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';

declare var $:any;

@Component({
  selector: 'cliente-modulo',
  templateUrl: './cliente-modulo.component.html',
  styleUrls: ['./cliente-modulo.component.scss']
})
export class ClienteModuloComponent implements OnInit, AfterViewInit{

  @Input("data") data:any     = null; 
  @Input("template") template = null;
  @ViewChild("slider") slider:any;
  public width = null;

  constructor(){}
  
  /**
   * 
   * Init slider
   * 
   */
  initSlider(){

    if(this.width <= 700){

      $(this.slider.nativeElement).slick({
        infinite: true,
        autoplay: true,
        autoplaySpeed: 1500,
        dots: false,
        slidesToShow: 3,
        responsive: [{
            breakpoint: 1224,
            settings: {
              slidesToShow: 3,  
            }
          },{
              breakpoint: 1024,
              settings: {
              slidesToShow: 2,
            }
          },
          {
              breakpoint: 768,
              settings: {
                  slidesToShow: 3,

              }
          },
          {
              breakpoint: 300,
              settings: {
                slidesToShow: 2,
                centerPadding: '0',
              }
          }
        ]
      });
      $(this.slider.nativeElement).slick('refresh');

    }

  }
  /**
   * 
   * On 
   * 
   */
   _onResize(){

    this.width = window.innerWidth; 
    this.initSlider();

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
    
  }
  /**
   * 
   * Init
   * 
   */
  ngAfterViewInit(): void {
    this.width = window.innerWidth;
    this.initSlider();
  }

}
