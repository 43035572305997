<div class="page page-servicos">
    <breadcrumbs [itens]="itensB"></breadcrumbs>
    <div class="page-body" *ngIf="response.status == 1">
        <div class="page-title" *ngIf="page.mostrar_titulo">
            <div class="container">
                <h1>{{page.titulo}}</h1>
                <h2 *ngIf="page.mostrar_subtitulo">{{page.subtitulo}}</h2>
            </div>    
        </div>
        <div class="page-content">
            <section class="section section-descricao">
                <div class="container"> 
                    <article class="descricao" [innerHtml]="page.texto | safeHtml"></article> 
                </div>    
            </section> 
            <section class="section section-servicos">  
                <modulo posicao="servicos" classCustom="_page-servico" template="page-servico"></modulo>  
            </section>    
        </div>
    </div>
    <div class="page-body" *ngIf="response.status == 500">
        <page-error (reload)="getData()"></page-error>   
    </div>    
</div>        
<loader [hidden]="!loader" message="Carregando. Aguarde!"></loader> 