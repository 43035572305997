<div class="case-item">
    <div class="foto" *ngIf="data.ativar_link">
        <a [href]="data.link_site" target="_blank" [title]="data.nome">
            <img  [src]="data.previa_foto" width="700px" [alt]="data.nome" >
        </a>  
    </div> 
    <div class="foto" *ngIf="!data.ativar_link">
        <img [src]="data.previa_foto" width="700px" [alt]="data.nome" >
    </div>   
</div>  
