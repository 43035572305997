<div class="page">
    <breadcrumbs [itens]="itensB"></breadcrumbs> 
    <div class="page-body" *ngIf="response.status == 1">
        <div class="page-title" *ngIf="page.mostrar_titulo">
            <div class="container">
                <h1>{{page.titulo}}</h1>
                <h2 *ngIf="page.mostrar_subtitulo">{{page.subtitulo}}</h2>
            </div>    
        </div>
        <div class="page-content">
            <section class="section section-image"> 
                <figure>  
                    <img defaultImage="/images/pixel.png" lazyLoad="images/icons/aplicativo.png" alt="Aplicativo ManageTour Pos" />
                </figure>    
            </section> 
            <section class="section section-descricao">
                <div class="container"> 
                    <article class="descricao" [innerHtml]="page.texto | safeHtml"></article> 
                </div>    
            </section>
            <section class="section section-funcionalidades">
                <div class="container"> 
                    <div class="row">
                        <div class="col-md-4" *ngFor="let item of funcionalidades"> 
                            <funcionalidade-item [data]="item"></funcionalidade-item>
                        </div>     
                    </div>  
                </div>    
            </section>
        </div>
    </div>
    <div class="page-body" *ngIf="response.status == 500">
        <page-error (reload)="getData()"></page-error>   
    </div>    
</div>        
<loader [hidden]="!loader" message="Carregando. Aguarde!"></loader> 