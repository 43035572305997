<div class="page page-contato">
    <div class="page-body" *ngIf="response.status == 1">
        <div class="page-title" *ngIf="page.mostrar_titulo">
            <div class="container">
                <h1 class="wow slideInDown">{{page.titulo}}</h1>
                <h2 class="wow slideInUp" *ngIf="page.mostrar_subtitulo">{{page.subtitulo}}</h2>
            </div>    
        </div>
        <div class="page-content">
            <section class="section section-form">
                <div class="container"> 
                    <div class="row">
                        <div class="col-md-6">
                            <form class="form form-custom box-sombra wow slideInDown" autocomplete="off" (submit)="enviar()">
                                <div class="form-group">
                                    <label>Nome: </label>
                                    <input type="text" [(ngModel)]="dataItem.nome" name="nome" class="form-control" placeholder="Ex: João da Silva" />
                                </div>
                                <div class="form-group">
                                    <label>E-mail: </label>
                                    <input type="text" [(ngModel)]="dataItem.email" name="email" class="form-control" placeholder="nome@provedor.com.br" />
                                </div>
                                <div class="form-group">
                                    <label>Telefone: </label>
                                    <input type="text" [(ngModel)]="dataItem.telefone" name="telefone" class="form-control" phoneMask placeholder="(99) 99999-9999" />
                                </div>
                                <div class="form-group">
                                    <label>Mensagem: </label>
                                    <textarea [(ngModel)]="dataItem.mensagem" name="mensagem" class="form-control"></textarea>
                                </div>
                                <div class="footer">  
                                    <button type="submit" class="btn btn-one">
                                        ENVIAR
                                    </button>  
                                </div> 
                            </form>
                        </div>
                        <div class="col-md-6">
                            <div class="content-logo animated-five infinite pulse">
                                <img src="/images/icons/logo-simbolo.svg" alt="Plugtour - Especialista em Tecnologia" />
                            </div>
                            <div class="section section-descricao wow slideInUp" *ngIf="page.mostrar_texto">
                                <article [innerHtml]="page.texto | safeHtml"></article>  
                            </div>
                        </div>
                    </div>
                </div>       
            </section>
        </div>
    </div>
    <div class="page-body" *ngIf="response.status == 500">
        <page-error (reload)="getData()"></page-error>   
    </div>    
</div>         
<loader [hidden]="!loader" [message]="message"></loader> 