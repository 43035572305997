export class RouteBase {

    public static API_BASE = "/api/site";

    public static MODULO = {
        modulos: RouteBase.API_BASE+"/modulo/modulo", // RESOURCE
        banner: RouteBase.API_BASE+"/modulo/modulo-banner",
        unidade: RouteBase.API_BASE+"/modulo/unidade",
        fale_conosco: RouteBase.API_BASE+"/modulo/contato/fale-conosco",
        dica_roteiro: RouteBase.API_BASE+"/modulo/dica-roteiro",
        newsletter: RouteBase.API_BASE+"/modulo/newsletter",
        contato_consultor: RouteBase.API_BASE+"/modulo/contato/consultor",
        contato: RouteBase.API_BASE+"/modulo/contato/contato",
        contato_sugestao: RouteBase.API_BASE+"/modulo/contato/sugestao",
    }
    public static CONTEUDO = {
        page: RouteBase.API_BASE+"/conteudo/page"
    }


}
