import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor() { }

   /**
    * 
    * Retorna os dados do módulo 
    *
    */
  getModulos(){ 
    
    if(typeof(sessionStorage) != "undefined"){
          
      let data = sessionStorage.getItem("modulos");
    
      if(data != null){
        return JSON.parse(data)
      }else{
        return null
      }
    
    }else{
      return null
    }
    
  }
  /**
   * 
   * Seta os dados do módulos 
   * 
   * @param data 
   * 
   */
  setModulos(data){

    if(typeof(sessionStorage) != "undefined"){
      sessionStorage.setItem("modulos",JSON.stringify(data));
    }

  }
  /**
   * 
   * Seta os dados dos projetos
   * 
   * @param data 
   * 
   */
  setProjetos(data){
    
    if(typeof(sessionStorage) != "undefined"){
      sessionStorage.setItem("projetos",JSON.stringify(data));
    }
    
  }
  /**
   * 
   * Seta a configuração
   * 
   * @param data 
   * 
   */
  setConfiguracao(data){
    
    if(typeof(sessionStorage) != "undefined"){
      sessionStorage.setItem("configuracao",JSON.stringify(data));
    }
    
  }
  /**
  * 
  * Retorna os dados do módulo 
  *
  */
  getProjetos(){
      
    if(typeof(sessionStorage) != "undefined"){
            
      let data = sessionStorage.getItem("projetos");
    
      if(data != null){
        return JSON.parse(data)
      }else{
        return null
      }
      
    }else{
      return null
    }
      
  }
  /**
  * 
  * Retorna os dados do módulo 
  *
  */
  getConfiguracao(){
    
    if(typeof(sessionStorage) != "undefined"){
            
      let data = sessionStorage.getItem("configuracao");
    
      if(data != null){
        return JSON.parse(data)
      }else{
        return null
      }
      
    }else{
      return null
    }
    
  }
  setTotalCart(data){
    
    if(typeof(sessionStorage) != "undefined"){
      sessionStorage.setItem("totalCart",JSON.stringify(data));
    }
    
  }
  getTotalCart(){
    
    if(typeof(sessionStorage) != "undefined"){
          
      let data = sessionStorage.getItem("totalCart");
    
      if(data != null){
        return JSON.parse(data)
      }else{
        return null
      }
    
    }else{
      return null
    }

  }
  /**
   * 
   * Seta a Reserva
   * 
   * @param data 
   * 
   */
  setReserva(data){
    
    if(typeof(sessionStorage) != "undefined"){
      sessionStorage.setItem("reserva",JSON.stringify(data));
    }
    
  }
  /***
   * 
   * Retorna os dados da Reserva
   * 
   * 
   */
  getReserva(){
    
    if(typeof(sessionStorage) != "undefined"){
          
      let data = sessionStorage.getItem("reserva");
      
      if(data != null){
        return JSON.parse(data).length == 0 ? null : JSON.parse(data);
      }else{
        return null
      }
    
    }else{
      return null
    }
    
  }
  /**
   * 
   * Seta o usuário
   * 
   */
  setUsuario(usuario){
    
    sessionStorage.setItem("usuario",JSON.stringify(usuario));
      
  }
  /**
   * 
   * Retorna o usuário da Sessão
   * 
   */
  getUsuario(){ 
    
    let data = sessionStorage.getItem("usuario");

    if(typeof(data) != null){
      return JSON.parse(data);
    }else{
      return null;
    }    
    
  }
  /**
   * 
   * Remove o Usuário
   * 
   */
  removeUsuario(){
    sessionStorage.removeItem("usuario");
  }
  /**
   * 
   * Seta o usuário
   * 
   */
  setConfirmacaoEmail(){
    
    sessionStorage.setItem("confirmacao_email",'true');
      
  }
  /**
   * 
   * Seta o usuário
   * 
   */
  getConfirmacaoEmail(){
    
    let data = sessionStorage.getItem("confirmacao_email");

    if(data != null){
      return true;
    }else{
      return false;
    } 
      
  }
  /**
   * 
   * Remove a confrmação de email
   * 
   */
  removeConfirmacaoEmail(){

    sessionStorage.removeItem("confirmacao_email"); 

  }
  /**
    * 
    * Retorna os dados do módulo 
    *
    */
   getCategorias(){
    
    if(typeof(sessionStorage) != "undefined"){
          
      let data = sessionStorage.getItem("categorias");
    
      if(data != null){
        return JSON.parse(data);
      }else{
        return [];
      }
    
    }else{
      return [];
    }
    
  }
  /**
   * 
   * Seta os dados do módulos 
   * 
   * @param data 
   * 
   */
  setCategorias(data){

    if(typeof(sessionStorage) != "undefined"){
      sessionStorage.setItem("categorias",JSON.stringify(data)); 
    }

  }
  /**
    * 
    * Retorna os dados do módulo 
    *
    */
   getComboProdutos(){
    
    if(typeof(sessionStorage) != "undefined"){
          
      let data = sessionStorage.getItem("combo_produtos");
    
      if(data != null){
        return JSON.parse(data)
      }else{
        return []
      }
    
    }else{
      return []
    }
    
  }
  /**
   * 
   * Seta os dados do módulos 
   * 
   * @param data 
   * 
   */
  setComboProdutos(data){

    if(typeof(sessionStorage) != "undefined"){
      sessionStorage.setItem("combo_produtos",JSON.stringify(data)); 
    }

  }
  /**
    * 
    * Retorna os dados do módulo 
    *
    */
   getRegiaoMenu(){
    
    if(typeof(sessionStorage) != "undefined"){
          
      let data = sessionStorage.getItem("regiao_menu");
    
      if(data != null){
        return JSON.parse(data)
      }else{
        return []
      }
    
    }else{
      return []
    }
    
  }
  /**
   * 
   * Seta os dados do módulos 
   * 
   * @param data 
   * 
   */
  setRegiaoMenu(data){

    if(typeof(sessionStorage) != "undefined"){
      sessionStorage.setItem("regiao_menu",JSON.stringify(data)); 
    }

  }
  /**
    * 
    * Retorna os dados do módulo 
    *
    */
   getCidade(){
    
    if(typeof(sessionStorage) != "undefined"){
          
      let data = sessionStorage.getItem("cidade");
    
      if(data != null){
        return JSON.parse(data)
      }else{
        return null
      }
    
    }else{
      return null
    }
    
  }
  /**
   * 
   * Seta os dados do módulos 
   * 
   * @param data 
   * 
   */
  setCidade(data){

    if(typeof(sessionStorage) != "undefined"){
      sessionStorage.setItem("cidade",JSON.stringify(data));
    }

  }
  /**
    * 
    * Retorna os dados do módulo 
    *
    */
   getCategoriasMenu(){
    
    if(typeof(sessionStorage) != "undefined"){
          
      let data = sessionStorage.getItem("categorias_menu");
    
      if(data != null){
        return JSON.parse(data)
      }else{
        return null
      }
    
    }else{
      return null
    }
    
  }
  /**
   * 
   * Seta os dados do módulos 
   * 
   * @param data 
   * 
   */
  setCategoriasMenu(data){

    if(typeof(sessionStorage) != "undefined"){
      sessionStorage.setItem("categorias_menu",JSON.stringify(data));
    }

  }
  /**
    * 
    * Retorna os dados do módulo 
    *
    */
   getPopup(){
    
    if(typeof(sessionStorage) != "undefined"){
          
      let data = sessionStorage.getItem("popup");
    
      if(data != null){
        return data;
      }else{
        return null
      }
    
    }else{
      return null
    }
    
  }
  /**
   * 
   * Seta os dados do módulos 
   * 
   * @param data 
   * 
   */
  setPopup(){

    if(typeof(sessionStorage) != "undefined"){
      sessionStorage.setItem("popup",'true');
    }

  }


}
