import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'depoimento-item',
  templateUrl: './depoimento-item.component.html',
  styleUrls: ['./depoimento-item.component.scss']
})
export class DepoimentoItemComponent implements OnInit {

  @Input("data") data:any = {};
  public openTexto:any = false;

  constructor(){}

  /**
   * 
   * Open Texto
   * 
   */
  _openTexto(){

    this.openTexto = this.openTexto ? false : true;  

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{}

}
