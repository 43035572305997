<footer class="footer-partial">
    <div class="content">  
        <div class="container">
            <div class="row">
                <div class="col-md-4">
                    <div class="content-logo">
                        <figure>
                            <img src="/images/logos/logo-footer.svg"  alt="Plugtour - Especialista em Tecnologia" width="170px" />
                        </figure>
                    </div>
                </div>
                <div class="col-md-4 center-fle">
                    <div class="item">
                        <div class="title">
                            <h3>Menu</h3>
                        </div>
                        <div class="item-content">
                            <ul class="menu">
                                <li>
                                    <a routerLink="/" title="Plugtour - Especialista em Tecnologia">
                                        Home
                                    </a>
                                </li>
                                <li>
                                    <a routerLink="/quem-somos" title="Plugtour - Sobre a Plugtour">
                                        Sobre Nós
                                    </a>    
                                </li>
                                <li>
                                    <a routerLink="/contato" title="Contato - Fale com os nossos especialistas">
                                        Contato
                                    </a>    
                                </li>
                                <li>
                                    <a routerLink="/politicas-de-privacidade" title="Políticas de Privacidade - Plugtour">
                                        Políticas de Privacidade
                                    </a>      
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">  
                    <div class="item">
                        <div class="title">
                            <h3>Fale com A Gente</h3>
                        </div>
                        <div class="item-content">
                            <modulo posicao="contato" template="footer"></modulo> 
                        </div>
                    </div>
                </div>
            </div>    
        </div>   
    </div>
    <div class="copy">
        <div class="container">
            <p>
                <span>© Plugtour 2023 - Desenvolvido por</span>
                <a href="https://digitalmovement.com.br" title="Digita Movement - Especialista em Tecnologia">
                    <span>Digital Movement</span>
                    <img src="/assets/logos/digital-movement.svg" alt="Digita Movement - Especialista em Tecnologia" width="20px" />
                </a>
            </p>
        </div>    
    </div>    
</footer>
