<div class="module-contact-body" [ngSwitch]="template">
    <div class="module contact" *ngSwitchCase="null">
        <div class="module-title">
            <h3>{{data.titulo}}</h3>
        </div> 
        <div class="module-content">
            <ul>
                <li>
                    <div class="icon">
                        <i class="material-icons">phone</i>
                    </div>
                    <div class="text">
                        <a href="tel:{{data.params.celular}}">{{data.params.celular}}</a>
                    </div>    
                </li>
                <li>
                    <div class="icon">
                        <i class="material-icons">email</i>
                    </div>
                    <div class="text">
                        <a href="mailto:{{data.params.email}}">{{data.params.email}}</a>
                    </div>    
                </li>    
            </ul>    
        </div>      
    </div>
    <div class="module two" *ngSwitchCase="'two'">
        <div class="contact-list-module">
            <div class="container">
                <div class="row">
                    <div class="col-md-4" (click)="_click()">
                        <div class="item">
                            <div class="icon">
                                <img src="/images/icons/whatsapp.svg" alt="WhatsApp" width="45px" />
                            </div>
                            <div class="text">
                                <strong>WhatsApp</strong>
                                <span>{{data.params.whatsapp}}</span>
                            </div>
                        </div> 
                    </div>
                    <div class="col-md-4">
                        <div class="item item-border">
                            <div class="icon">
                                <img src="/images/icons/email.svg" alt="E-mail" width="45px" />
                            </div>
                            <div class="text">
                                <strong>E-mail</strong>
                                <a href="mailto:{{data.params.email}}">{{data.params.email}}</a>
                            </div>  
                        </div>
                    </div>
                    <div class="col-md-4" (click)="toInstagram()">
                        <div class="item">
                            <div class="icon">
                                <img src="/images/icons/instagram.svg" alt="Instagram" width="45px" />
                            </div>
                            <div class="text">
                                <strong>Instagram</strong>
                                <span>@plugtour.com.br</span>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>  
        </div>      
    </div>
    <div class="module footer" *ngSwitchCase="'footer'">
        <div class="list">
            <div class="list-item">
                <div class="icon">
                    <i class="fa-solid fa-envelope"></i>
                </div>
                <div class="text">
                    <span>{{data.params.email}}</span>  
                </div>
            </div>
            <div class="list-item" (click)="_click()">
                <div class="icon">
                    <i class="fa-brands fa-whatsapp"></i>
                </div>
                <div class="text">
                    <span>{{data.params.whatsapp}}</span>  
                </div>
            </div>
        </div>      
    </div>
</div>    
