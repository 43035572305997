<div class="modulo modulo-cases">
    <div class="modulo-titulo">
        <div class="container"> 
            <h2 [innerHtml]="data.params.texto"></h2>    
        </div>     
    </div>    
    <div class="modulo-content">
        <div class="container">
            <div class="cases">
                <div class="linhas">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <ul>
                    <li *ngFor="let c of data.cases" [style.background]="c.cor" (click)="_onClick(c)">
                        <figure> 
                            <img defaultImage="/images/pixel.png" [lazyLoad]="c.logo" [alt]="c.nome" />
                        </figure>    
                    </li>
                </ul>
            </div>    
        </div>
    </div>    
</div>    
