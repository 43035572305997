<div class="depoimento-item box-sombra">
    <div class="foto">
        <img [src]="data.foto" [title]="data.nome" width="120px" />
    </div>
    <div class="content">
        <h3>{{data.nome}}</h3>
        <div class="content-instagram" *ngIf="data.instagram_ativo">
            <a [href]="data.instagram_link" target="_blank">
                {{data.instagram_conta}}
            </a>
        </div>
        <div class="content-depoimento"> 
            <div class="content-texto" [ngClass]="{'open': openTexto}">
                <article [innerHtml]="data.texto | safeHtml"></article>
            </div>
            <div class="content-button" [ngClass]="{'open': openTexto}">
                <button type="button" (click)="_openTexto()">
                    <span>Ler tudo</span>
                    <div class="icon">
                        <i class="fa-solid fa-chevron-down"></i>
                    </div>
                </button>
            </div>
        </div>
        <div class="content-site" *ngIf="data.site_ativo">
            <a [href]="data.site_link" target="_blank"class="btn btn-one">
                Acesse o Site
            </a>
        </div>
    </div>
</div>