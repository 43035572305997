import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ModuloService {

  public updateModulosEmitter = new EventEmitter();
  public getModulosEmitter    = new EventEmitter(); 

  constructor(private http: HttpClient){

  }
  
  filterModulosByPosition(modulos,position){

    try{
      let out = [];

      if(modulos != null){
        modulos.forEach(element => {

          try{
            if(element.posicao == position){
              element.params = typeof(element.params) == "string" ? JSON.parse(element.params) : element.params;
              out.push(element);
            }
          }catch(e){
            //console.log(e.message);
          }  

        });
      }  

      return out;
    
    }catch(e){
      //console.log(e.message);
    }  

  }
  
  

}
