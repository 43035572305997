<div class="module about-ecommerce-module" (window:resize)="_onResize()">
    <div class="box box-about">
        <div class="container">
            <div class="title">
                <h2 class="wow slideInUp" data-wow-delay="0.2s">{{data.titulo}}</h2>
                <h3 class="wow slideInDown" data-wow-delay="0.5s">{{data.subtitulo}}</h3>
            </div>
            <div class="linha"></div>
            <div class="description wow slideInUp" data-wow-delay="0.5s" [innerHtml]="data.params.texto"></div>
            <div class="case-slider">
                <div class="slider-items" #slider>  
                    <div class="slider-item" *ngFor="let d of data.cases">
                        <case-item class="center" [data]="d"></case-item>
                    </div>   
                </div>
                <div class="slide-navigate-item prev template-2" (click)="prev()">
                    <i class="material-icons">navigate_before</i>
                </div>
                <div class="slide-navigate-item next template-2" (click)="next()">
                    <i class="material-icons">navigate_next</i>
                </div>
            </div>
        </div>
    </div>
    <div class="box box-features">
        <div class="container">
            <div class="title">
                <h2 class="wow slideInUp" data-wow-delay="0.5s">Conheça as principais funções da Plataforma</h2>
                <h3 class="wow slideInUp" data-wow-delay="0.8s">e potencialize as suas vendas</h3>
            </div> 
            <div class="content">
                <div class="row">
                    <div class="col-md-4 wow slideInDown" data-wow-delay="0.3s" *ngFor="let f of data.funcoes">
                        <div class="feature-item box-sombra">
                            <div class="icon"> 
                                <img [src]="f.icone_img" [alt]="f.titulo" height="60px"/>
                            </div>   
                            <h3>{{f.titulo}}</h3>  
                            <div class="content">  
                                <article [innerHtml]="f.descricao"></article>
                            </div>
                        </div> 
                    </div>
                </div>   
            </div>    
        </div>    
    </div>
    <div class="box box-button">
        <div class="container">
            <btn-consultor-demonstracao></btn-consultor-demonstracao>
        </div>
    </div>
</div>