import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalSugestao } from 'src/app/components/modal/modal-sugestao/modal-sugestao.component';

@Component({
  selector: 'modulo-servicos',
  templateUrl: './servicos.component.html',
  styleUrls: ['./servicos.component.scss']
})
export class ServicosComponent implements OnInit {

  @Input("data") data:any = null;
  @Input("template") template:any = null;
  @Input("classCustom") classCustom:any = null; 
  public servicos:any     = []; 
  
  constructor(
    private modal: MatDialog
  ){}
  /**
   * 
   * Seta os serviços
   * 
   */
  setServicos(){

    this.servicos.push({
      icon: "laptop_chromebook",
      titulo: "Sistema de Gestão",
      subtitulo: "AUTOMATIZA TODOS OS SEUS PROCESSOS GERENCIAIS",
      url: "nossos-servicos/sistema-de-gestao"
    });
    this.servicos.push({
      icon: "shopping_cart",
      titulo: "E-commerce",
      subtitulo: "VENDA MAIS! <br>VENDA PELA INTERNET",
      url: "nosssos-servicos/ecommerce"
    });
    this.servicos.push({
      icon: "settings_cell",
      titulo: "Aplicativo de Vendas",
      subtitulo: "VENDA INTEGRADA!",
      url: "nossos-servicos/aplicativo"
    });

  }
  /**
   * 
   * Modal Sugestão
   * 
   */
  _modalSugestao(){

    this.modal.open(ModalSugestao,{  
      width: "500px",
      data:{
        tipo: 5
      },
      panelClass: 'no-padding'
    });

  }
  /**
   * 
   * Modal Sugestão
   * 
   */
  _modalOutroServico(){

    this.modal.open(ModalSugestao,{  
      width: "500px",
      data:{
        tipo: 6 
      },
      panelClass: 'no-padding'
    });

  }
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit():void{
    this.setServicos();
  }

}
