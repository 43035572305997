<header #header class="animated-two fadeIn">
  <div class="content">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-center">
            <modulo class="logo" posicao="logo"></modulo> 
          </div>
          <div class="col-md-9 col-menu col-menu-desktop">
            <modulo-menu classCustom="menu-desktop"></modulo-menu>
          </div>
        </div>
      </div>
  </div> 
  <modulo-menu class="col-menu-mobile" classCustom="menu-mobile"></modulo-menu>
</header>
  