import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'modulo-feito-pra-voce',
  templateUrl: './feito-pra-voce.component.html',
  styleUrls: ['./feito-pra-voce.component.scss']
})
export class FeitoPraVoceComponent implements OnInit {

  @Input("data") data:any = {};
  public itens:any        = [];

  constructor(){}

  /**
   * 
   * Set data
   * 
   * 
   */
  setData(){

    this.itens = [{
      title: "Receptivos",
      icon: "/images/icons/receptivos.svg"
    },{
      title: "Venda de Ingressos",
      icon: "/images/icons/ingressos.svg"
    },{
      title: "Excursões",
      icon: "/images/icons/excursoes.svg"
    },{
      title: "Atrativos Turísticos",
      icon: "/images/icons/atrativos.svg"
    }];

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
    this.setData();
  }

}
