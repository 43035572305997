<div class="module about-app-module">
    <div class="box box-about">
        <div class="title">
            <div class="container">
                <h2 class="wow slideInUp" data-wow-delay="0.5s">{{data.titulo}}</h2>
                <h3 class="wow slideInDown" data-wow-delay="1s">{{data.subtitulo}}</h3>
                <div class="linha"></div>
            </div>
        </div>
        <div class="description wow fadeIn" data-wow-delay="1.1s">
            <div class="container">
                <article [innerHtml]="data.params.texto | safeHtml"></article>
            </div>   
        </div> 
        <div class="content-app">
            <div class="container">
                <div class="row">
                    <div class="col-md-5 list-pos">
                        <div class="item wow slideInUp" data-wow-delay="0.5s">
                            <figure>
                                <img src="/images/managetour/app/{{data.params.imagem_getnet}}" alt="Getnet" width="240px" />
                            </figure>
                        </div>
                        <div class="item wow slideInDown" data-wow-delay="0.7s">
                            <figure>
                                <img src="/images/managetour/app/{{data.params.imagem_lio}}" alt="Lio" width="190px" />
                            </figure> 
                        </div>
                    </div>
                    <div class="col-md-7">
                        <app-telas [data]="data.telas"></app-telas>
                    </div>    
                </div>    
            </div>  
        </div>    
    </div>
</div>    