import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'modal-info',
  templateUrl: './modal-info.component.html',
  styleUrls: ['./modal-info.component.scss']
})
export class ModalInfo implements OnInit {

  public titulo = "Atenção";

  constructor(
    public dialog: MatDialogRef<ModalInfo>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { 

    if(data.titulo != null){
      this.titulo = data.titulo;
    }

  }
  /**
   * 
   * Close modal
   * 
   */
  close(){
    this.dialog.close();
  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
  
  }

}
