<div class="contato-consultor">
    <form class="form-custom" (submit)="send()">
        <div class="form-group">
            <label>Nome*</label> 
            <input type="text" [(ngModel)]="dataItem.nome" name="nome" />
        </div> 
        <div class="row">  
            <div class="col-md-6">
                <div class="form-group">
                    <label>E-mail*</label> 
                    <input type="text" [(ngModel)]="dataItem.email" name="email" placeholder="nome@provedor.com" />
                </div> 
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>Telefone*</label> 
                    <input type="text" [(ngModel)]="dataItem.telefone" name="telefone" placeholder="(**) *****-****" mask="(99) 99999-9999" />
                </div> 
            </div>
        </div>
        <div class="form-group">
            <label>Empresa*</label> 
            <input type="text" [(ngModel)]="dataItem.empresa_nome" name="empresa_nome" placeholder="Nome da Empresa" />
        </div> 
        <div class="form-group">
            <label>Site</label> 
            <input type="text" [(ngModel)]="dataItem.empresa_site" name="empresa_site" placeholder="Informe o seu site" />
        </div>
        <div class="row">  
            <div class="col-md-6">
                <div class="form-group">
                    <label>Qual é o seu papel na empresa ?*</label> 
                    <select type="text" [(ngModel)]="dataItem.empresa_papel_id" name="empresa_papel_id">
                        <option *ngFor="let d of comboPapel" [value]="d.value">
                            <span>{{d.text}}</span>
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>O que você deseja obter com a Plugtour ?*</label> 
                    <select type="text" [(ngModel)]="dataItem.empresa_objetivo_id" name="empresa_objetivo_id">
                        <option *ngFor="let d of comboObjetivo" [value]="d.value">
                            <span>{{d.text}}</span>  
                        </option>
                    </select>
                </div>  
            </div>
        </div>
        <div class="form-group">
            <label>Mensagem</label> 
            <textarea [(ngModel)]="dataItem.mensagem" name="mensagem" placeholder="Escreva aqui um pouco mais sobre o que você deseja..." rows="5"></textarea>
        </div>
        <div class="form-group form-button">
            <button type="submit" class="btn btn-two">
                <span>Enviar</span>
            </button>
        </div>   
    </form> 
</div>
<loader *ngIf="loader"></loader>