<div *ngFor="let m of modulos" [ngSwitch]="m.tag">
  <div *ngSwitchCase="'logo'"> 
    <modulo-logo [data]="m" [classCustom]="classCustom"></modulo-logo>  
  </div>
  <div *ngSwitchCase="'banner-slider'">  
    <modulo-banner-slider [data]="m"></modulo-banner-slider>
  </div>
  <div *ngSwitchCase="'rede-social'"> 
    <modulo-rede-social [data]="m" [template]="template"></modulo-rede-social>
  </div>
  <div *ngSwitchCase="'newsletter'"> 
    <modulo-newsletter [data]="m" [template]="template"></modulo-newsletter>
  </div>
  <div *ngSwitchCase="'whatsapp'"> 
    <modulo-whatsapp [data]="m" [template]="template"></modulo-whatsapp>
  </div>
  <div *ngSwitchCase="'fale-conosco'">   
    <modulo-fale-conosco [data]="m" [template]="template"></modulo-fale-conosco>
  </div>
  <div *ngSwitchCase="'parceiros'">   
    <modulo-parceiros [data]="m"></modulo-parceiros>
  </div>
  <div *ngSwitchCase="'cases'">   
    <modulo-cases [data]="m"></modulo-cases>
  </div>
  <div *ngSwitchCase="'contato-consultor'">   
    <modulo-contato-consultor [data]="m"></modulo-contato-consultor>
  </div>
  <div *ngSwitchCase="'servicos'">     
    <modulo-servicos [data]="m" [classCustom]="classCustom" [template]="template"></modulo-servicos> 
  </div>
  <div *ngSwitchCase="'endereco'">       
    <endereco-modulo [data]="m" [template]="template"></endereco-modulo> 
  </div>
  <div *ngSwitchCase="'cliente'">       
    <cliente-modulo [data]="m" [template]="template"></cliente-modulo> 
  </div>
  <div *ngSwitchCase="'managetour'">       
    <managetour-modulo [data]="m" [template]="template"></managetour-modulo> 
  </div>
  <div *ngSwitchCase="'ecommerce'">       
    <ecommerce-modulo [data]="m" [template]="template"></ecommerce-modulo> 
  </div>
  <div *ngSwitchCase="'aplicativo'">       
    <aplicativo-modulo [data]="m" [template]="template"></aplicativo-modulo> 
  </div>
  <div *ngSwitchCase="'contato'">       
    <contato-modulo [data]="m" [template]="template"></contato-modulo> 
  </div>
  <div *ngSwitchCase="'feito-pra-voce'">       
    <modulo-feito-pra-voce [data]="m"></modulo-feito-pra-voce> 
  </div>
  <div *ngSwitchCase="'depoimento'">         
    <depoimento-modulo [data]="m"></depoimento-modulo> 
  </div>
</div>
  
  
  