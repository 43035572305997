<div class="page page-about">
    <div class="page-body" *ngIf="response.status == 1">
        <div class="page-title" *ngIf="page.mostrar_titulo">
            <div class="container">
                <h1 class="wow slideInUp">{{page.titulo}}</h1>
                <h2 class="wow slideInDown" *ngIf="page.mostrar_subtitulo">{{page.subtitulo}}</h2>
            </div>    
        </div>  
        <div class="page-content">
            <section class="section section-description" *ngIf="page.mostrar_texto">
                <div class="container"> 
                    <article class="wow slideInUp" [innerHtml]="page.texto | safeHtml"></article> 
                </div>    
            </section>
            <section class="section section-planos">
                <div class="container">
                    <div class="row">
                        <div class="col-md-4" *ngFor="let d of data">
                            <div class="plano-item">
                                <div class="header">
                                    <h3>{{d.title}}</h3> 
                                    <h4 [innerHtml]="d.subtitle | safeHtml"></h4>
                                </div>
                                <btn-consultor-demonstracao></btn-consultor-demonstracao>
                                <div class="content">
                                    <ul>
                                        <li *ngFor="let i of d.data">
                                            <div class="icon">
                                                <i class="fa-solid fa-check"></i>
                                            </div>
                                            <div class="text">
                                                <span>{{i.title}}</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
    <div class="page-body" *ngIf="response.status == 500">
        <page-error (reload)="getData()"></page-error>   
    </div>    
</div>        
<loader [hidden]="!loader" message="Carregando. Aguarde!"></loader> 