import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

declare var $:any;

@Component({
  selector: 'modulo-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit,AfterViewInit {

  @ViewChild("header") header:ElementRef;

  constructor(){ }
  
  /**
   * 
   * Seta a Class Fixed para o topo
   * 
   */
  setTopFixed(){

    let header = this.header.nativeElement;

    $(window).scroll(function(){ 
      
      var fromTop = $(this).scrollTop();
      var windowWidth = $(window).width();
      
      if(fromTop > 150){
        header.classList.add("animated-two","slideInDown","fixed");
      }else{
        header.classList.remove("fixed","animated-two","slideInDown");
      }

    });

  }
  /***
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit():void{
    
  }
  ngAfterViewInit(): void {
    this.setTopFixed();
  }

}
