<div class="page page-servicos page-sistema">
    <breadcrumbs [itens]="itensB"></breadcrumbs>
    <div class="page-body" *ngIf="response.status == 1">
        <div class="page-title" *ngIf="page.mostrar_titulo">
            <div class="container">
                <h1>{{page.titulo}}</h1>
                <h2 *ngIf="page.mostrar_subtitulo">{{page.subtitulo}}</h2>
            </div>    
        </div>
        <div class="page-content">
            <section class="section section-laptop">
                <figure>
                    <img src="images/icons/sistema-laptop.png" alt="Sistema Laptop" />
                </figure>    
            </section>    
            <section class="section section-descricao">
                <div class="container"> 
                    <article class="descricao" [innerHtml]="page.texto | safeHtml"></article> 
                </div>    
            </section>
            <section class="section section-funcionalidades">
                <div class="container">
                    <ul>
                        <li *ngFor="let item of funcionalidadesDestaques">
                            <funcionalidade-item [data]="item"></funcionalidade-item> 
                        </li>    
                    </ul>    
                </div>    
            </section>
            <section class="section section-funcionalidades principais">
                <div class="container">
                    <h2>Confira as principais <strong>funcionalidades</strong></h2>
                    <ul>
                        <li *ngFor="let item of funcionalidades">
                            <funcionalidade-item [data]="item" classCustom="principais"></funcionalidade-item>   
                        </li>    
                    </ul>    
                </div>
            </section>
        </div>
    </div>
    <div class="page-body" *ngIf="response.status == 500">
        <page-error (reload)="getData()"></page-error>   
    </div>    
</div>        
<loader [hidden]="!loader" message="Carregando. Aguarde!"></loader> 