import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'servicos-page',
  templateUrl: './servicos-page.component.html',
  styleUrls: ['./servicos-page.component.scss']
})
export class ServicosPage implements OnInit {

  public loader  = false;
  public apelido = "servicos";   
  public response = {
    data: "",
    status: null
  }
  public page:any     = {};
  public params:any   = {};
  public dataItem:any = {}
  public message      = "Carregando. Aguarde!";
  public itensB       = [{
    text: "Nossos Serviços"
  }]; 
  public funcionalidadesDestaques = [];
  public funcionalidades          = [];
  public showTitulo               = false;   

  constructor(
    private app: AppService,
    private api: ApiService
  ){ }

  /**  
   * 
   * Retorna os dados da página
   * 
   */
  getData(){

    try{

      this.message = "Enviando. Aguarde!";  
      this.loader  = true;

      this.api.conteudo().page(this.apelido).subscribe(response => {

        this.loader = false;   

        if(response.status == 1){
                
          if(typeof(response.data.page) != "undefined"){
            
            this.response = {  
              data: "",
              status: 1
            }

            this.page     = typeof(response.data.page.data[0]) != "undefined" ? response.data.page.data[0] : response.data.page.data;
            this.params   = this.page.params != null ? JSON.parse(this.page.params) : {};
            
            this.app.setMetaDados(this.page.meta_title,this.page.meta_description); 
              
          }
      
      
        }else{
          this.response = {
            data: response.data,
            status: 500 
          }
        }
        setTimeout(() => {
          this.app.toTop(); 
        },200);
        
      },(response) => {

        this.loader = false;
        setTimeout(() => {
          this.app.toTop(); 
        },200);
      
      });

    }catch(e){

      this.loader = false;

    }

  }
  /***
   * 
   * Inicializa as Funções 
   * 
   */
  ngOnInit(): void {
    this.getData();
  }

}
