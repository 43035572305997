import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContatoConsultorFormComponent } from '../../contato/contato-consultor-form/contato-consultor-form.component';

@Component({
  selector: 'btn-consultor-demonstracao',
  templateUrl: './btn-consultor-demonstracao.component.html',
  styleUrls: ['./btn-consultor-demonstracao.component.scss']
})
export class BtnConsultorDemonstracaoComponent implements OnInit {

  constructor(
    public dialog: MatDialog
  ) { }

  /**
   * 
   * Open modal contato
   * 
   ***/
  openModalContato(){

    this.dialog.open( 
      ContatoConsultorFormComponent,{
        width: "650px"
      }  
    );
  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit(): void {
  }

}
