import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'contato-consultor-two',  
  templateUrl: './contato-consultor.component.html',
  styleUrls: ['./contato-consultor.component.scss']
})
export class ContatoConsultorTwoComponent implements OnInit {

  public loader       = false;
  public dataItem:any = {};
  public comboPapel = [{
    value: 1,
    text: "Proprietário"
  },{
    value: 2,
    text: "Sócio"
  },{
    value: 3,
    text: "Gerente"
  },{
    value: 4,
    text: "Marketing e/ou Vendas"
  },{
    value: 5,
    text: "Reservas"
  },{
    value: 6,
    text: "Eu não trabalho para uma empresa"
  }];  
  public comboObjetivo = [{
    value: 1,
    text: "Aumentar minhas vendas online"
  },{
    value: 2,
    text: "Ter mais canais de venda online"
  },{
    value: 3,
    text: "Melhorar a Gestão das Minhas Reservas"
  },{
    value: 4,
    text: "Reduzir o tempo de operação"
  },{
    value: 5,
    text: "Automatizar o meu processo de venda"
  },{
    value: 7,
    text: "Outro"    
  }];  
  @Output("success") success:any = new EventEmitter();  

  constructor(
    private api: ApiService,
    private app: AppService
  ){}

  /**
   * 
   * Get objetivo
   * 
   * @returns 
   * 
   */
  getObjetivo(){

    let data = null;

    for (let index = 0; index < this.comboObjetivo.length; index++) {
      
      if(this.comboObjetivo[index].value ==  this.dataItem.empresa_objetivo_id){
        data = this.comboObjetivo[index].text;
        break;
      }
      
    }

    return data;

  }
  /**
   * 
   * Get objetivo
   * 
   * @returns 
   * 
   */
  getPapel(){

    let data = null;

    for (let index = 0; index < this.comboPapel.length; index++) {
      
      if(this.comboPapel[index].value ==  this.dataItem.empresa_papel_id){
        data = this.comboPapel[index].text;
        break;
      }
      
    }

    return data;

  }
  /**
   * 
   * Enviar
   * 
   */
   send(){

    try{

      this.loader = true; 

      this.dataItem.empresa_objetivo = this.getObjetivo();
      this.dataItem.empresa_papel = this.getPapel();

      this.api.contato().consultor(this.dataItem).subscribe(response => {

        this.loader = false;

        if(response.status == 1){

          this.app.info("Obrigado pelo seu contato. Em breve retornaremos.","success");
          this.success.emit(null);
          this.initDataItem();
          
        }else{

          this.app.info("Aconteceu algo inesperado. Tente novamente.","danger",null,null,"Houve um erro");

        }

      },(response) => {

        this.loader = false;

        let error = this.api.formatError(response);

        if(error.status == 422){ 
          this.app.info(error.message,"danger");
        }else{
          this.app.info(error.message,"danger",null,null,"Houve um erro");
        }

      });


    }catch(e){

      this.loader = false;
      this.app.info(e.message,"danger");

    }

  }
  /**
   * 
   * Incializa o dataItem
   * 
   */
   initDataItem(){

    this.dataItem = {
      nome: "",
      telefone: "",
      email: "",
      mensagem: "",
      empresa_nome: "",
      empresa_site: "",
      empresa_papel_id: null,
      empresa_papel:"",
      empresa_objetivo: "",
      empresa_objetivo_id: null,
      tipo: 3
    }

  }
  /**
   * 
   * Init data item
   * 
   */
  ngOnInit(): void {
    this.initDataItem();
  }

}
