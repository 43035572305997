import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePage } from './pages/home-page/home-page.component';
import { ModulosResolver } from './resolvers/modulos.resolver'; 
import { ContatoPage } from './pages/contato-page/contato-page.component';
import { SobrePage } from './pages/sobre-page/sobre-page.component';
import { SejaNossoParceiroPage } from './pages/seja-nosso-parceiro-page/seja-nosso-parceiro-page.component';
import { PoliticasDePrivacidadePageComponent } from './pages/politicas-de-privacidade-page/politicas-de-privacidade-page.component';
import { PlanosPageComponent } from './pages/planos-page/planos-page.component';

const routes: Routes = [{
  path: '',   
  component: HomePage,
  resolve:{   
    modulos: ModulosResolver
  }  
},{
  path: 'contato',   
  component: ContatoPage, 
  resolve:{   
    modulos: ModulosResolver
  }  
},{
  path: 'quem-somos',   
  component: SobrePage,  
  resolve:{   
    modulos: ModulosResolver 
  }  
},{
  path: 'planos',   
  component: PlanosPageComponent,  
  resolve:{   
    modulos: ModulosResolver 
  }  
},{
  path: 'politicas-de-privacidade',   
  component: PoliticasDePrivacidadePageComponent,  
  resolve:{   
    modulos: ModulosResolver 
  }  
},{
  path: '**',
  pathMatch: 'full', 
  redirectTo: ''
}];

@NgModule({
  imports: [RouterModule.forRoot(routes,{relativeLinkResolution:'legacy'})], 
  exports: [RouterModule]
})
export class AppRoutingModule { }
