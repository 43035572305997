import { Component, Input, OnInit, ViewChild } from '@angular/core';

declare var $:any;

@Component({
  selector: 'app-telas',
  templateUrl: './app-telas.component.html',
  styleUrls: ['./app-telas.component.scss']
})
export class AppTelasComponent implements OnInit {

  @ViewChild("slider") slider:any;
  @Input("data") data:any = [];

  constructor(){}
  
  /***
   * 
   * Inicializa o Slider
   * 
   */
   initSlider(){

    $(this.slider.nativeElement).slick({ 
      infinite: true,
      slidesToShow: 3,
      dots: false, 
      arrows: false,
      centerMode: true,
      centerPadding: '20px',
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [{
          breakpoint: 1224,
          settings: {
            slidesToShow: 3,  
          }
      },{
          breakpoint: 1024,
          settings: {
          slidesToShow: 3,
        }
      },
      {
          breakpoint: 768,
          settings: {
              slidesToShow: 3,

          }
      },
      {
          breakpoint: 540,
          settings: {
            slidesToShow: 2,
            centerPadding: '0',
          }
      }
    ]
    
    }); 
    $(this.slider.nativeElement).slick('refresh'); 
    

  }
  /**
   * 
   * Next
   * 
   */
   prev(){ 

    $(this.slider.nativeElement).slick("slickPrev");  

  }
  /**
   * 
   * Next
   * 
   */
  next(){

    $(this.slider.nativeElement).slick("slickNext"); 

  }
  /**
   * 
   * Set Data
   * 
   */
  ngOnInit(): void {
    
  }
  /**
   * 
   * Init
   * 
   */
  ngAfterViewInit(): void {
    this.initSlider();
  }


}
