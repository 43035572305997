import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'aplicativo-modulo',
  templateUrl: './applicativo-modulo.component.html',
  styleUrls: ['./applicativo-modulo.component.scss']
})
export class ApplicativoModuloComponent implements OnInit {

  @Input("data") data:any         = {};
  @Input("template") template:any = {};

  constructor(){}

  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{}

}
