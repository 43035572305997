<div class="modulo modulo-parceria">
    <div class="modulo-titulo">
        <div class="container">
            <h2>Trabalhamos em <strong>parceria</strong> <span> | Automatização e Vendas para Turismo Receptivo</span></h2>
        </div>     
    </div>    
    <div class="modulo-content">
        <div class="container"> 
            <article [innerHtml]="data.params.texto" class="descricao"></article>
            <div class="empresas">
                <div class="row"> 
                    <div class="col-lg-4 col-md-6 col-empresa" *ngFor="let p of parcerias">
                        <div class="empresa">
                            <figure>
                                <img defaultImage="/images/pixel.png" [lazyLoad]="p.logo" [alt]="p.nome" />
                            </figure> 
                            <a [href]="p.site" [title]="p.nome" target="_blank">
                                <div class="content">
                                    <span>Parceria</span>
                                    <h4>
                                        <mat-icon>keyboard_arrow_right</mat-icon>
                                        <span>{{p.nome}}</span>
                                    </h4>
                                </div>
                            </a>    
                        </div>    
                    </div>   
                </div>    
            </div>    
        </div>    
    </div>    
</div>   
