<div class="modulo modulo-feito-pra-voce">
    <div class="modulo-title" *ngIf="data.mostrar_titulo">
        <div class="container">
            <h2 class="wow slideInUp" data-wow-delay="0.5s">{{data.titulo}}</h2>
            <h3 class="wow slideInDown" data-wow-delay="0.5s">{{data.subtitulo}}</h3>
        </div>
    </div>
    <div class="modulo-content">
        <div class="container">
            <div class="itens">
                <div class="row">
                    <div class="col-md-3" *ngFor="let i of itens;let index=index;">
                        <div class="item wow slideInUp" data-wow-delay="0.5s">
                            <div class="icon">
                                <img [src]="i.icon" [title]="i.title" width="60px" />
                            </div>
                            <h4>{{i.title}}</h4>  
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="box box-button">
            <div class="container">
                <btn-consultor-demonstracao></btn-consultor-demonstracao>
            </div>
        </div>
    </div>
</div>