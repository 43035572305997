<div class="sub-menu">   
    <div class="container">  
      <ul itemscope itemtype="https://schema.org/Menu">
        <li itemprop="hasMenuItem" itemscope itemtype="https://schema.org/MenuItem" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
          <a routerLink="nossos-servicos/sistema-de-gestao">
            <mat-icon>laptop_chromebook</mat-icon>
            <span itemprop="name" class="sub-titulo">Sistema de Gestão</span>
            <span class="sub-subtitulo">TURISMO RECEPTIVO</span>
          </a>
        </li> 
          <li itemprop="hasMenuItem" itemscope itemtype="https://schema.org/MenuItem" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <a routerLink="nossos-servicos/ecommerce">
              <mat-icon>shopping_cart</mat-icon>
              <span itemprop="name" class="sub-titulo">E-commerce</span>
              <span class="sub-subtitulo">A SUA LOJA VIRTUAL</span>
            </a>
          </li> 
          <li itemprop="hasMenuItem" itemscope itemtype="https://schema.org/MenuItem" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <a routerLink="nossos-servicos/aplicativo">
              <mat-icon>settings_cell</mat-icon>
              <span itemprop="name" class="sub-titulo">Aplicativo de Vendas</span>
              <span class="sub-subtitulo">TUDO QUE VOCÊ PRECISA</span>
            </a>
          </li>  
          <!--<li itemprop="hasMenuItem" itemscope itemtype="https://schema.org/MenuItem" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <a routerLink="nossos-servicos/dashboard">
              <mat-icon>signal_cellular_alt</mat-icon>
              <span itemprop="name" class="sub-titulo">Dashboard Operacional</span>
              <span class="sub-subtitulo">ACOMPANHE EM TEMPO REAL</span>
            </a>
          </li>--> 
    </ul> 
  </div>   
</div>