import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomePage } from './pages/home-page/home-page.component';
import { Modulo } from './modulos/modulo/modulo.component';
import { ModuloLogo } from './modulos/logo/logo.component';
import { MenuComponent } from './components/menu/menu.component';
import { ModuloService } from './services/modulo.service';
import { DataService } from './services/data.service';
import { AppService } from './services/app.service';
import { HttpClientModule } from '@angular/common/http';
import { HeaderComponent } from './components/header/header.component';
import { BannerSliderComponent } from './modulos/banner-slider/banner-slider.component';
import { RedeSocialComponent } from './modulos/rede-social/rede-social.component';
import { NewsletterComponent } from './modulos/newsletter/newsletter.component';
import { WhatsappComponent } from './modulos/whatsapp/whatsapp.component';
import { LazyLoadImageModule } from 'ng-lazyload-image'; //@3.4.2
import { DeviceDetectorModule } from 'ngx-device-detector';
import { FormsModule } from '@angular/forms';
import { FooterComponent } from './components/footer/footer.component';
import { FaleConoscoComponent } from './modulos/fale-conosco/fale-conosco.component';
import { PhoneMaskDirective } from './directives/phone-mask.directive';
import { ParceirosComponent } from './modulos/parceiros/parceiros.component';  
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SistemaDeGestaoPage } from './pages/servicos/sistema-de-gestao-page/sistema-de-gestao-page.component';
import { EcommercePage } from './pages/servicos/ecommerce-page/ecommerce-page.component';
import { AplicativoPage } from './pages/servicos/aplicativo-page/aplicativo-page.component';
import { DashboardPage } from './pages/servicos/dashboard-page/dashboard-page.component';
import { ContatoPage } from './pages/contato-page/contato-page.component';
import { SobrePage } from './pages/sobre-page/sobre-page.component'; 
import { SejaNossoParceiroPage  } from './pages/seja-nosso-parceiro-page/seja-nosso-parceiro-page.component';
import { CasesComponent } from './modulos/cases/cases.component';
import { ModalInfo } from './components/modal/modal-info/modal-info.component';
import { LoaderComponent } from './components/loader/loader/loader.component';
import { ContatoConsultorComponent } from './modulos/contato-consultor/contato-consultor.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { ServicosComponent } from './modulos/servicos/servicos.component'; 
import { GtagService } from './services/gtag.service';
import { PageErrorComponent } from './components/error/page-error/page-error.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { MenuServicosComponent } from './components/menu-servicos/menu-servicos.component';
import { FuncionalidadeItemComponent } from './components/servicos/funcionalidade-item/funcionalidade-item.component';
import { CaseItemComponent } from './components/cases/case-item/case-item.component';
import { ServicosPage } from './pages/servicos/servicos-page/servicos-page.component';
import { ModalSugestao } from './components/modal/modal-sugestao/modal-sugestao.component';
import { TopoPartialComponent } from './components/partial/topo-partial/topo-partial.component';
import { EnderecoModuloComponent } from './modulos/endereco-modulo/endereco-modulo.component';
import { ClienteModuloComponent } from './modulos/cliente-modulo/cliente-modulo.component';
import { ManagetourModuloComponent } from './modulos/managetour-modulo/managetour-modulo.component';
import { EcommerceModuloComponent } from './modulos/ecommerce-modulo/ecommerce-modulo.component';
import { ApplicativoModuloComponent } from './modulos/applicativo-modulo/applicativo-modulo.component';
import { AppTelasComponent } from './components/aplicativo/app-telas/app-telas.component';
import { ContatoModuloComponent } from './modulos/contato-modulo/contato-modulo.component';
import { MapaModuloComponent } from './modulos/mapa-modulo/mapa-modulo.component';
import { MaskDirective } from './directives/mask.directive';
import { ContatoConsultorFormComponent } from './components/contato/contato-consultor-form/contato-consultor-form.component';
import { FeitoPraVoceComponent } from './modulos/feito-pra-voce/feito-pra-voce.component';
import { PoliticasDePrivacidadePageComponent } from './pages/politicas-de-privacidade-page/politicas-de-privacidade-page.component';
import { DepoimentoComponent } from './modulos/depoimento/depoimento.component';
import { DepoimentoItemComponent } from './components/depoimento/depoimento-item/depoimento-item.component';
import { PlanosPageComponent } from './pages/planos-page/planos-page.component';
import { BtnConsultorDemonstracaoComponent } from './components/btn/btn-consultor-demonstracao/btn-consultor-demonstracao.component';
import { ContatoConsultorTwoComponent } from './components/contato/contato-consultor/contato-consultor.component';

@NgModule({
  declarations: [
    AppComponent,
    HomePage,
    Modulo,
    ModuloLogo, 
    MenuComponent,
    HeaderComponent,
    BannerSliderComponent,
    RedeSocialComponent,
    NewsletterComponent,
    WhatsappComponent,
    FooterComponent,
    FaleConoscoComponent,
    PhoneMaskDirective,
    ParceirosComponent,
    SistemaDeGestaoPage,
    EcommercePage,
    AplicativoPage,
    DashboardPage,
    ContatoPage,
    SobrePage,
    SejaNossoParceiroPage,
    CasesComponent,
    ModalInfo,
    LoaderComponent,
    ContatoConsultorComponent,
    SafeHtmlPipe,
    ServicosComponent,
    PageErrorComponent,
    BreadcrumbsComponent,
    MenuServicosComponent,
    FuncionalidadeItemComponent,
    CaseItemComponent,
    ServicosPage,
    ModalSugestao,
    TopoPartialComponent,
    EnderecoModuloComponent,
    ClienteModuloComponent,
    ManagetourModuloComponent,
    EcommerceModuloComponent,
    ApplicativoModuloComponent,
    AppTelasComponent,
    ContatoModuloComponent,
    MapaModuloComponent,
    MaskDirective,
    ContatoConsultorFormComponent,
    FeitoPraVoceComponent,
    PoliticasDePrivacidadePageComponent,
    DepoimentoComponent,
    DepoimentoItemComponent,
    PlanosPageComponent,
    BtnConsultorDemonstracaoComponent,
    ContatoConsultorTwoComponent
  ],
  exports: [
    ContatoConsultorFormComponent,
    ContatoConsultorComponent,
    ContatoConsultorTwoComponent
  ],
  entryComponents: [
    ModalInfo,
    ModalSugestao
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    LazyLoadImageModule, 
    DeviceDetectorModule.forRoot(), 
    FormsModule,
    MatIconModule,
    MatDialogModule
  ],
  providers: [
    ModuloService,
    DataService,
    AppService,
    GtagService
  ],
  bootstrap: [AppComponent]
})
export class AppModule{ }
