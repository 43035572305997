import { Component, OnInit, Input } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { ApiService } from 'src/app/services/api.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'modulo-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit {

  @Input("data") data:any = {}; 
  @Input("template") template:any = "";
  public dataItem:any = {};
  public loader       = false;
  public message = {
    data: "",
    class: "",
    show: false
  };
  public subscribeMessage = null;
  public messageLoader = "Enviando. Aguarde!"; 

  constructor(
    private api: ApiService,
    private app: AppService,
    private recaptchaV3Service: ReCaptchaV3Service
  ){ }

  /**
   * 
   * Envia os dados do contato para a news
   * 
   */
  send(){

    try{

      this.loader = true;

      this.recaptchaV3Service.execute('importantAction').subscribe((token) => {

        this.dataItem.token = token;

        this.api.modulos().newsletter(this.dataItem).subscribe(r => {

          let response:any = r;
          let message  = "";
          this.loader = false; 

          switch(response.status){
            case 1:

              message = "Inscrição realizada com sucesso.";
              this.app.info(message,"success");
              this.initDataItem();
              
            break;
            case 0:
              
              let errors = this.app.getErrors([response.data]);
              this.app.info(errors,"danger")

            break;
            default:
            
              message = "Houve um erro interno. Tente mais tarde ou informe nossa equipe.";
              this.app.info(message,"danger");
              
            break;
          }

        },(response) => {

          this.loader = false;
          
          let error = this.api.formatError(response);

          this.app.info(error.message,"danger");


        });

      },(response) => { 

        this.loader = false;
        this.app.info("Token de validação inválido. Recarregue a página para continuar.");

      });


    }catch(e){

    }

  }
  /**
   * 
   * Inicializa o dataItem
   * 
   * 
   */
  initDataItem(){

    this.dataItem = {
      nome: "",
      email: "",
      celular: null
    }

  }
  /**
   * 
   * Converte para string o dataItem
   * 
   */
  toString(){

    return JSON.stringify(this.dataItem);
    
  }
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit() {
    this.initDataItem();
  }

}
