<div class="module-whatsapp" (click)="open()">
  <div class="content">
      <span>Fale por <br><strong>WhatsApp!</strong></span>
      <div class="icon">
          <img src="/images/icons/whatsapp-white.svg" alt="WhatsApp" width="15px" height="15px" />
      </div>  
  </div> 
  <div class="content mobile">
      <div class="icon">
          <img src="/images/icons/whatsapp-white.svg" alt="WhatsApp" width="15px" height="15px" />
      </div>  
  </div>   
</div>    