<div class="module contact-module">
    <div class="module-title">
        <div class="container">
            <h2 class="wow slideInDown" data-wow-delay="0.2s" *ngIf="data.mostrar_titulo">{{data.titulo}}</h2>
            <h3 class="wow slideInUp" data-wow-delay="0.4s" *ngIf="data.mostrar_subtitulo">{{data.subtitulo}}</h3>
        </div>
    </div>
    <div class="module-content">
        <div class="container"> 
            <div class="row"> 
                <div class="col-md-6"> 
                    <div class="box-sombra"> 
                        <contato-consultor-two></contato-consultor-two>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="logo-icon animated-five infinite pulse">
                        <figure>
                            <img src="/images/icons/logo-simbolo.svg" width="150px" />
                        </figure>
                    </div>
                    <div class="content-chamada wow slideInDown" data-wow-delay="0.8s">
                        <article [innerHtml]="data.params.texto | safeHtml"></article>
                    </div>
                </div>
            </div>
        </div>    
    </div>    
</div> 
<loader [hidden]="!loader"></loader> 