import { Component, OnInit, Input } from '@angular/core';

declare var window:any;

@Component({
  selector: 'modulo-cases',
  templateUrl: './cases.component.html',
  styleUrls: ['./cases.component.scss']
}) 
export class CasesComponent implements OnInit {

  @Input("data") data:any = {};

  constructor() { }

  /**
   * 
   * 
   */
  _onClick(item){

    if(item.ativar_link){
      window.open(item.link_site,"_target")
    }

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit():void{
    
  }

}
