import { Component, Input, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'contato-modulo',
  templateUrl: './contato-modulo.component.html',
  styleUrls: ['./contato-modulo.component.scss']
})
export class ContatoModuloComponent implements OnInit {

  @Input("data") data:any         = null;
  @Input("template") template:any = null;
  public prefixo              = "web";
  public numero               = "";

  constructor(
    private deviceService: DeviceDetectorService
  ) { }

  /**
   * 
   * Open WhatsApp
   * 
   */
  _click(){

    window.open("https://"+this.prefixo+".whatsapp.com/send?phone="+this.numero,"__blank");
    
  }
  /***
   * 
   * To Facebook
   * 
   */
  toFacebook(){

    window.open(this.data.params.facebook,"_blank");

  }
  /***
   * 
   * To Facebook
   * 
   */
   toInstagram(){

    window.open(this.data.params.instagram,"_blank");

  }
  /**
   * 
   * Seta o número
   * 
   */
   setNumero(){
    
    let telefone = this.data.params.whatsapp.replace("(","").replace(")","").replace("-","").replace(" ","");

    this.numero = "55"+telefone;


  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit(): void {
    this.setNumero();
    this.prefixo = this.deviceService.isDesktop() ? "web" : "api";
  }

}
