import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';

declare var $:any;

@Component({
  selector: 'ecommerce-modulo',
  templateUrl: './ecommerce-modulo.component.html',
  styleUrls: ['./ecommerce-modulo.component.scss']
})
export class EcommerceModuloComponent implements OnInit,AfterViewInit {

  @Input("data") data:any         = {};
  @Input("template") template:any = {};
  @ViewChild("slider") slider:any;


  constructor(){ }
  
  /***
   * 
   * Inicializa o Slider
   * 
   */
   initSlider(){  

    $(this.slider.nativeElement).slick({   
      infinite: true,
      slidesToShow: 1,
      dots: false, 
      arrows: false,
      centerMode: true, 
      autoplay: true,
      autoplaySpeed: 4000,
      centerPadding: '0'
    }); 
    $(this.slider.nativeElement).slick('refresh'); 
    

  }
   /**
   * 
   * On 
   * 
   */
    _onResize(){

      this.initSlider();  
  
    }
  /**
   * 
   * Next
   * 
   */
   prev(){ 

    $(this.slider.nativeElement).slick("slickPrev");  

  }
  /**
   * 
   * Next
   * 
   */
  next(){   

    $(this.slider.nativeElement).slick("slickNext"); 

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{}
  /**
   * 
   * Init
   * 
   */
  ngAfterViewInit(): void {
    this.initSlider();
  }

}
