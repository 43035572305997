import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'modulo-parceiros',
  templateUrl: './parceiros.component.html',
  styleUrls: ['./parceiros.component.scss']
})
export class ParceirosComponent implements OnInit {

  @Input("data") data:any = null; 
  public parcerias = [];

  constructor() { }

  /**
   * 
   * Seta as parecerias
   * 
   */
  setParcerias(){

    this.parcerias.push({
      nome: "Digital Movement",
      site: "https://digitalmovement.com.br",
      logo: "images/parceria/digital-movement.svg"
    });
    this.parcerias.push({
      nome: "Penha Solutions",
      site: "http://penhasolutions.com.br",
      logo: "images/parceria/penha-solutions.png"
    });

  }
  /**
   * 
   * Incializa as Funções
   * 
   */
  ngOnInit(): void {
    this.setParcerias();
  }

}
