<div class="modulo fale-conosco">
    <div class="modulo-titulo" *ngIf="data.mostrar_titulo">
        <h3>
            <span>{{data.titulo}}</span>
            <span class="divisor">|</span>
            <span>{{data.subtitulo}}</span>
        </h3>
    </div>
    <div class="modulo-content">
        <div class="content-form">
            <form class="form" (submit)="enviar()">
                <div class="row">
                    <div class="col-md-6">
                        <input type="text" [(ngModel)]="dataItem.nome" name="nome" placeholder="Informe seu nome" />
                    </div> 
                    <div class="col-md-6"> 
                        <input type="text" [(ngModel)]="dataItem.email" name="email" placeholder="Informe seu e-mail" />
                    </div>   
                </div> 
                <div class="row">
                    <div class="col-md-6">
                        <input type="text" [(ngModel)]="dataItem.telefone" name="telefone" placeholder="Qual seu telefone?" phoneMask />
                    </div> 
                    <div class="col-md-6">
                        <input type="text" [(ngModel)]="dataItem.empresa" name="empresa" placeholder="Qual a sua empresa?" />
                    </div>   
                </div>
                <div class="row"> 
                    <div class="col-md-12"> 
                        <textarea [(ngModel)]="dataItem.mensagem" name="mensagem" placeholder="Escreva algo para nós..." rows="5"></textarea>
                    </div>   
                </div> 
                <div class="row">
                    <div class="col-md-12">
                        <button type="submit" class="btn-one">
                            ENVIAR
                        </button>    
                    </div>    
                </div>  
                <div class="linha-01"></div>  
                <div class="linha-02"></div>  
            </form>    
        </div>    
    </div>    
</div>
<loader [hidden]="!loader"></loader>

