import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePage implements OnInit {

  constructor(
    private app: AppService
  ){ }

  /***
   * 
   * Inicializa Iniciliazação
   * 
   * 
   **/
  ngOnInit():void{
    
  }


}
