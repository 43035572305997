<div class="app-telas">
    <div class="app-slider-items slick-padding-mobile" #slider>  
        <div class="slider-item" *ngFor="let d of data">
            <div class="item">
                <img [src]="d.imagem" alt="App ManageTour POS" width="100%" />
            </div>
        </div>      
    </div>
    <div class="slide-navigate-item prev template-2" (click)="prev()">
        <i class="material-icons">navigate_before</i>
    </div>
    <div class="slide-navigate-item next template-2" (click)="next()">
        <i class="material-icons">navigate_next</i>
    </div>  
</div>
