<div class="modulo modulo-servicos {{classCustom}}">
    <div class="modulo-titulo" *ngIf="template != 'page-servico'">
        <div class="container">
            <h2 [innerHtml]="data.params.texto"></h2>
        </div>    
    </div>
    <div class="modulo-content">
        <section class="section">
            <div class="container">
                <div class="servicos">
                    <div class="row">
                        <div class="col-lg-3 col-md-6" *ngFor="let item of servicos;let i=index;">
                            <div class="item">
                                <mat-icon class="center">{{item.icon}}</mat-icon>
                                <h3 class="center">{{item.titulo}}</h3>
                                <h4 class="center" [innerHtml]="item.subtitulo"></h4>
                                <a routerLink="{{item.url}}" title="{{item.titulo}} - {{item.subtitulo}}" class="btn-one center">
                                    SAIBA MAIS 
                                </a>    
                            </div>   
                        </div>    
                    </div> 
                </div>    
            </div> 
        </section>
        <section class="section section-two"> 
            <div class="section-title">
                <div class="container">
                    <h2>Não encontrou o que <strong>você precisa?</strong></h2>
                    <h3>Entre em contato com a nosa equipe. Queremos entender a sua necesidade.</h3>
                </div>
            </div>    
            <div class="container">
                <ul>
                    <li> 
                        <button type="button" class="btn-two" (click)="_modalSugestao()">
                            Quero dar uma sugestão
                        </button>
                    </li>
                    <li>
                        <button type="button" class="btn-one" (click)="_modalOutroServico()">
                            Não encontrei o que preciso
                        </button> 
                    </li>
                </ul>    
            </div>  
        </section>      
    </div>    
</div>    