import { Component, OnInit, Input } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'modulo-fale-conosco',
  templateUrl: './fale-conosco.component.html',
  styleUrls: ['./fale-conosco.component.scss']
})
export class FaleConoscoComponent implements OnInit {

  @Input("data") data:any     = null; 
  @Input("template") template = null;
  public dataItem:any = {};
  public loader  = false;

  constructor(
    private app: AppService,
    private api: ApiService
  ){ }

  /**
   * 
   * Enviar
   * 
   */
  enviar(){

    try{

      this.loader = true;
      
      this.api.contato().fale_conosco(this.dataItem).subscribe(response => {
        
        this.loader = false;

        if(response.status == 1){
          this.app.info("Recebemos a sua mensagem. Logo entraremos em contato.","success");
          this.initDataItem();
        }else{
          this.app.info("Houve um problema ao enviar a sua solicitação. Tente novamente.","danger");
        }

      },(response) => {

        this.loader = false;

        let error = this.api.formatError(response);

        this.app.info(error.message,"danger");

      });

    }catch(e){
      this.app.info(e.message,"danger");
      this.loader = false;
    }

  }
  /**
   * 
   * Inicializa a dataItem
   * 
   */
  initDataItem(){

    this.dataItem = {
      nome: "",
      email: "",
      telefone: "",
      empresa: "",
      mensagem: "",
      tipo: 2
    }


  }
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(): void {
    this.initDataItem();
  }

}
