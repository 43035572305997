<div class="module depoimento-module">
    <div class="module-title" *ngIf="data.mostrar_titulo">
        <div class="container">
            <h3 class="wow slideInDown" data-wow-delay="0.5s">{{data.titulo}}</h3>
            <h4 class="wow slideInUp" data-wow-delay="1s">{{data.subtitulo}}</h4>
        </div>
    </div>
    <div class="module-content">
        <div class="depoimentos">
            <div class="container">
                <div class="slider-items" #slider>     
                    <div class="slider-item" *ngFor="let d of data.depoimentos">
                        <depoimento-item [data]="d"></depoimento-item>
                    </div>
                </div>
                <div class="swiper-button-prev sw-btn" (click)="prev()">
                    <img src="/images/icons/prev.svg" alt="Arrow" />
                </div>
                <div class="swiper-button-next sw-btn" (click)="next()">
                    <img src="/images/icons/next.svg" alt="Arrow" />
                </div>
            </div>
        </div>
    </div>    
</div>    