import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'contato-consultor-form',
  templateUrl: './contato-consultor-form.component.html',
  styleUrls: ['./contato-consultor-form.component.scss']
})
export class ContatoConsultorFormComponent implements OnInit {

  constructor(
    private api: ApiService,
    private app: AppService,
    public dialog: MatDialogRef<ContatoConsultorFormComponent>, 
  ){}

  /**
   * 
   * Success
   * 
   */
  _success(data:any){ 

    this.dialog.close();

  }
  /**
   * 
   * Init data item
   * 
   */
  ngOnInit():void{}

}
