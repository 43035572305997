<div class="page page-politicas">
    <div class="page-body" *ngIf="response.status == 1">
        <div class="page-title" *ngIf="page.mostrar_titulo">
            <div class="container">
                <h1 class="wow slideInUp">{{page.titulo}}</h1>
                <h2 class="wow slideInDown" *ngIf="page.mostrar_subtitulo">{{page.subtitulo}}</h2>
            </div>    
        </div>
        <div class="page-content">
            <section class="section section-description">
                <div class="container">
                    <article [innerHtml]="page.texto | safeHtml"></article>
                </div>
            </section>
        </div>
    </div>
    <div class="page-body" *ngIf="response.status == 500">
        <page-error (reload)="getData()"></page-error>   
    </div>    
</div>        
<loader [hidden]="!loader" message="Carregando. Aguarde!"></loader> 