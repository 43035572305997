import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';

declare var $:any;

@Component({
  selector: 'depoimento-modulo',
  templateUrl: './depoimento.component.html',
  styleUrls: ['./depoimento.component.scss']
})
export class DepoimentoComponent implements OnInit,AfterViewInit {

  @Input("data") data:any     = null; 
  @Input("template") template = null;
  public dataItem:any = {};
  public loader  = false;
  @ViewChild("slider") slider:any;

  constructor(){}
  

  /***
   * 
   * Inicializa o Slider
   * 
   */
  initSlider(){  

    $(this.slider.nativeElement).slick({   
      infinite: true,
      slidesToShow: 3,
      dots: false, 
      arrows: false,
      centerMode: true, 
      autoplay: false,
      autoplaySpeed: 4000,
      centerPadding: '0',
      responsive: [{
        breakpoint: 1224,
        settings: {  
          slidesToShow: 3,  
        }
        },{
            breakpoint: 1024,
            settings: {
            slidesToShow: 2,
          }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,

            }
        },
        {
            breakpoint: 540,
            settings: {
              slidesToShow: 1,
              centerPadding: '0',
            }
        }]
    }); 
    $(this.slider.nativeElement).slick('refresh'); 
    

  }
  /**
   * 
   * Next
   * 
   */
  prev(){ 

    $(this.slider.nativeElement).slick("slickPrev");  

  }
  /**
   * 
   * Next
   * 
   */
  next(){

    $(this.slider.nativeElement).slick("slickNext"); 

  }
  /***
   * 
   * Init
   * 
   */
  ngOnInit():void{}
  /**
   * 
   * After view init
   * 
   */
  ngAfterViewInit(): void {
    this.initSlider();
  }

}
