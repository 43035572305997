import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RouteBase } from '../routes/base';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient){

  }
  /***
   * 
   * Conteúdo
   * 
   */
  conteudo(){

    return {
      page: (apelido):any => {

        let url    = RouteBase.CONTEUDO.page+"/"+apelido;

        return this.http.get(url);  

      }
    }

  }
  /***
   * 
   * Contato
   * 
   */
  contato(){

    return {
      fale_conosco: (params):any => {

        let url = RouteBase.MODULO.fale_conosco;
        return this.http.post(url,params); 

      },
      consultor: (params):any => {

        let url = RouteBase.MODULO.contato_consultor;
        return this.http.post(url,params); 

      },
      contato: (params):any => { 

        let url = RouteBase.MODULO.contato;
        return this.http.post(url,params); 

      },
      sugestao: (params):any => {

        let url = RouteBase.MODULO.contato_sugestao;
        return this.http.post(url,params); 

      }
    }

  }
  /**
   * 
   * Modulos
   * 
   */
  modulos(){

    return {
      getModulos: ():any => {

        let url = RouteBase.MODULO.modulos;
 
        return this.http.get(url);

      },
      newsletter: (params):any => {
        
        let url = RouteBase.MODULO.newsletter
        return this.http.post(url,params);
      
      }
    }

  }
  /**
   * 
   * Formata o erro da API
   * 
   */
  formatError(response){

    let error = {
      status: null,
      statusText: null,
      message: null
    }

    if(response.statusText){
      error.statusText = response.statusText;
    }
    if(response.status){
      error.status = response.status;
    }
    if(error.status == 405){
      error.message = "Método não permitido.";
    }
    if(response.error && error.message == null){
      
      let body = response.error;

      if(error.status == 422){

        error.message = this.formarErrorsValidation([body.errors]);

      }else if(body.message){
        error.message = body.message;
      }

    }else{
      error.message = response.data;
    }

    return error;


  }
  /**
   * 
   * Formata as mensagens de erro 
   * 
   * @param err 
   * @param paxs 
   * 
   */
  formarErrorsValidation(err,paxs=null){
    
    let messages = ""
    
    if(typeof(err) == "string"){
      messages = err
    }else{
      if(err.length > 1){
           
        for(let key in err) {
          if(paxs){
            let i = parseInt(key)+1 
            messages += "<h6>Passageiro: "+i+"<h6>"
          }
          for(let k in err[key]){
            messages += err[key][k]+"<br>"
          }
                   
        }
      }else{
        for(let key in err[0]) {
          messages += err[0][key]+"<br>"
        }
     }  
    
    }
    
    return messages;
    
  }
  
}
