import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'planos-page',
  templateUrl: './planos-page.component.html',
  styleUrls: ['./planos-page.component.scss']
})
export class PlanosPageComponent implements OnInit {

  public loader  = false;
  public apelido = "planos";  
  public response = {
    data: "",
    status: null
  }
  public page:any   = {};
  public params:any = {};
  public itensB = [{
    text: "Planos"
  }];
  public valores:any = [];
  public data:any = [];

  constructor(
    private app: AppService,
    private api: ApiService
  ){}

  /**
   * 
   * Retorna os dados da página
   * 
   */
  getData(){

    try{

      this.loader = true;

      this.api.conteudo().page(this.apelido).subscribe(response => {
        
        this.loader = false;   

        if(response.status == 1){
                
          if(typeof(response.data.page) != "undefined"){
            
            this.response = { 
              data: "",
              status: 1
            }

            this.page      = typeof(response.data.page.data[0]) != "undefined" ? response.data.page.data[0] : response.data.page.data;
            this.params    = this.page.params != null ? JSON.parse(this.page.params) : {};
            this.valores   = response.data.page.valores;

            this.app.setMetaDados(this.page.meta_title,this.page.meta_description); 
              
          }  
      
      
        }else{
          this.response = {
            data: response.data,
            status: 500
          }
        }
        this.app.toTop(); 

      },(response) => {

        this.loader = false;
        this.app.toTop(); 
        
      
      });

    }catch(e){

      this.loader = false;

    }

  }
  /**
   * 
   * Set Data
   * 
   * 
   */
  setData(){

    this.data.push({
      title: "Básico",
      subtitle: "Para que deseja ter um site profissional para fazer vendas integrado com o <strong>WhatsApp</strong>.",
      data: [{
        title: "Integração com o WhatsApp."
      },{
        title: "Formulário Inteligente."
      },{
        title: "Integração com Chat."
      },{
        title: "Customização da Loja."
      },{
        title: "Domínio Próprio."
      },{
        title: "Cadastro de Produtos ilimitados."
      }]
    },{
      title: "Venda",
      subtitle: "Aumente as suas <strong>vendas</strong> e ganhe produtividade com uma <strong>gestão efetiva</strong>.",
      data: [{
        title: "Motor de Reservas com múltiplos meios de pagamentos."
      },{
        title: "Gestão de Tarifários."
      },{
        title: "Controle de Frequência e Disponibilidade."
      },{
        title: "Gestão de Reservas."
      },{
        title: "Customização avançada da Loja."
      }]
    },{
      title: "Avançado",
      subtitle: "Recursos avançados para <strong>escalar o seu negócio</strong> e pontencializar os teus <strong>resultados</strong>.",
      data: [{
        title: "Motor de Reservas com múltiplos meios de pagamentos."
      },{
        title: "Gestão de Tarifários."
      },{
        title: "Controle de Frequência e Disponibilidade."
      },{
        title: "Gestão de Reservas."
      },{
        title: "Cupom de Desconto."
      },{
        title: "Links de Reserva."
      },{
        title: "Programa de Afiliados."
      },{
        title: "Gestão Financeira."
      },{
        title: "Gestão Operacional."
      },{
        title: "Grupo de Mentoria no WhatsApp."
      },{
        title: "Desenvolvimento de Módulos Personalizados."
      },{
        title: "Módulo de Avaliações."
      },{
        title: "Módulo de Abandono de Carrinho."
      }]
    });

  }
  /**
   * 
   * Inicilaiza as Funções
   * 
   */
  ngOnInit():void{
    this.getData();
    this.setData();
  }

}
