import { Component, OnInit, Input } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'sistema-de-gestao-page',
  templateUrl: './sistema-de-gestao-page.component.html',
  styleUrls: ['./sistema-de-gestao-page.component.scss']
})
export class SistemaDeGestaoPage implements OnInit {

  public loader  = false;
  public apelido = "sistema-de-gestao";  
  public response = {
    data: "",
    status: null
  }
  public page:any     = {};
  public params:any   = {};
  public dataItem:any = {}
  public message      = "Carregando. Aguarde!";
  public itensB       = [{
    text: "Nossos Serviços"
  },{
    text: "Sistema de Gestão"
  }];
  public funcionalidadesDestaques = [];
  public funcionalidades          = [];

  constructor(
    private app: AppService,
    private api: ApiService
  ){ }

  /**  
   * 
   * Retorna os dados da página
   * 
   */
  getData(){

    try{

      this.message = "Enviando. Aguarde!";  
      this.loader  = true;

      this.api.conteudo().page(this.apelido).subscribe(response => {

        this.loader = false;   

        if(response.status == 1){
                
          if(typeof(response.data.page) != "undefined"){
            
            this.response = {  
              data: "",
              status: 1
            }

            this.page            = typeof(response.data.page.data[0]) != "undefined" ? response.data.page.data[0] : response.data.page.data;
            this.params          = this.page.params != null ? JSON.parse(this.page.params) : {};
            this.filtrarFuncionalidades(response.data.page.funcionalidades); 
            
            this.app.setMetaDados(this.page.meta_title,this.page.meta_description); 
              
          }
      
      
        }else{
          this.response = {
            data: response.data,
            status: 500 
          }
        }
        setTimeout(() => {
          this.app.toTop(); 
        },200);
        
      },(response) => {

        this.loader = false;
        setTimeout(() => {
          this.app.toTop(); 
        },200);
      
      });

    }catch(e){

      this.loader = false;

    }

  }
  /**
   * 
   * Filtro
   * 
   */
  filtrarFuncionalidades(funcionalidades){

    let destaques    = [];
    let notDestaques = [];

    for (let index = 0;index < funcionalidades.length;index++) {
      
      if(funcionalidades[index].destaque){

        destaques.push(funcionalidades[index]);

      }else{

        notDestaques.push(funcionalidades[index]);

      }
      
    }

    this.funcionalidadesDestaques = destaques;
    this.funcionalidades          = notDestaques;

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit():void{
    this.getData();
  }

}
