import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'endereco-modulo',
  templateUrl: './endereco-modulo.component.html',
  styleUrls: ['./endereco-modulo.component.scss']
})
export class EnderecoModuloComponent implements OnInit {

  @Input("data") data:any     = null; 
  @Input("template") template = null;
  public dataItem:any = {};
  public loader  = false;

  constructor(){}

  /***
   * 
   * Init
   * 
   */
  ngOnInit():void{}

}
