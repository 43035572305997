import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'menu-servicos',
  templateUrl: './menu-servicos.component.html',
  styleUrls: ['./menu-servicos.component.scss']
})
export class MenuServicosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void { 
  }

}
