import { Component } from '@angular/core';
import { AppService } from './services/app.service';
import { NavigationEnd, NavigationStart, Router,Event as NavigationEvent } from '@angular/router';
import { GtagService } from './services/gtag.service';
import { filter } from 'rxjs/operators';

declare var $:any;
declare var WOW:any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  
  public title  = 'site';
  public loader = true; 

  constructor(
    private app: AppService,
    private router: Router,
    private gtag: GtagService
  ){

    this.onEvents()
  
  }
  /**
   * 
   * On Events
   * 
   */
  onEvents(){

    this.router.events.forEach((event: NavigationEvent) => {
      
      if(event instanceof NavigationStart){
        this.loader = true
      }
      
      if(event instanceof NavigationEnd) {

        setTimeout(()=>{
          this.loader = false;
        },200);
        this.initWOW();

      }

    });
    this.app.emitChangePage.subscribe(response => {
      this.loader = false;
    });
    this.app.setStatusServidorEmitter.subscribe(code => {
      //this.statusCode = code;
    });
    const navEndEvents = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    );
    navEndEvents.subscribe((event: NavigationEnd) => {
      this.gtag.sendPagePath(event.urlAfterRedirects); 
    }); 

  }
  /**
   * 
   * Init WOW
   * 
   */
   initWOW(){

    let wow = new WOW();

    wow.init(); 

    $('.wow').on('scrollSpy:exit', () => {
  
      $(this).css({
          'visibility': 'hidden',
          'animation-name': 'none'
      }).removeClass('animated');

      wow.addBox(this);     

    }).scrollSpy();
        
          
  }

}
